import React from 'react'

const Blog = () => {
    return (
        <div className='flex justify-center items-center h-screen'>
            <h1 className='text-4xl font-bold ' >Comming sooooooooooooooooooon</h1>
        </div>
    )
}

export default Blog